import React from "react"
import { Container, Row, Col } from "reactstrap"
import "./services.scss"

import Service from "./service"

import Bulldozer from "../images/icons/icon-bulldozer-74x74.png"
import Tassels from "../images/icons/icon-tassels-70x70.png"
import Ruler from "../images/icons/icon-ruler-45x71.png"

const Services = () => {
  const card = [
    {
      title: `Programacion`,
      icon: Bulldozer,
      description: `Brindamos servicios de sistemas informaticos de alta calidad a las pymes , grandes empresas y usuarios finales.`,
    },
    {
      title: `Automatizacion`,
      icon: Tassels,
      description: `Transformamos tareas tediosas y repetitivas en tareas que se realizan con un simple click.`,
    },
    {
      title: `Diseño`,
      icon: Ruler,
      description: `Nuestro equipo de expertos tiene años de experiencia en el diseño de  soluciones de tanto programas como dispositivos electronicos.`,
    },
  ]
  return (
    <div className="services" id="services">
      <Container>
        <Row>
          
        {card.map(service => {
            return (
              <Col  md="6" lg="4" key={service.title}>
                <Service services={service} />
              </Col>
            )
          })}
        </Row>
      </Container>
    </div>
  )
}

export default Services
